import { createSlice } from '@reduxjs/toolkit';
import { createRole, deleteRole, getAllRoles, getRoleById, updateRole } from './roleThunk';
// ----------------------------------------------------------------------
const initialState = {
    rolesMeta: null,
    // get roles & premissions
    roles: [],
    getRolesStatus: 'idle',
    getRolesError: '',
    // get one role with it's permissions by id
    getOneRoleStatus: 'idle',
    oneRole: null,
    getOneRoleError: '',
    // create one Role
    createOneRoleStatus: 'idle',
    createOneRoleError: '',
    // delete one Role 
    deleteOneRoleStatus: 'idel',
    deleteOneRoleError: '',
    // updateOneRole
    updateOneRoleStatus: 'idle',
    updateOneRoleError: '',
};
const slice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllRoles.pending, (state) => {
            state.getRolesStatus = 'loading';
        })
            .addCase(getAllRoles.fulfilled, (state, { payload }) => {
            state.getRolesStatus = 'succeeded';
            state.roles = payload?.docs;
            state.rolesMeta = payload?.meta;
        })
            .addCase(getAllRoles.rejected, (state, { error }) => {
            state.getRolesStatus = 'failed';
            state.getRolesError = error?.message || 'An error occured';
        });
        builder
            .addCase(getRoleById.pending, (state) => {
            state.getOneRoleStatus = 'loading';
        })
            .addCase(getRoleById.fulfilled, (state, { payload }) => {
            state.getOneRoleStatus = 'succeeded';
            state.oneRole = payload;
        })
            .addCase(getRoleById.rejected, (state, { error }) => {
            state.getOneRoleStatus = 'failed';
            state.oneRole = null;
            state.getOneRoleError = error?.message || 'An error occured';
        });
        builder
            .addCase(updateRole.pending, (state) => {
            state.updateOneRoleStatus = 'loading';
        })
            .addCase(updateRole.fulfilled, (state, { payload }) => {
            state.updateOneRoleStatus = 'succeeded';
            state.oneRole = payload;
        })
            .addCase(updateRole.rejected, (state, { error }) => {
            state.updateOneRoleStatus = 'failed';
            state.oneRole = null;
            state.updateOneRoleError = error?.message || 'An error occured';
        });
        builder
            .addCase(createRole.pending, (state) => {
            state.createOneRoleStatus = 'loading';
        })
            .addCase(createRole.fulfilled, (state, { payload }) => {
            state.createOneRoleStatus = 'succeeded';
        })
            .addCase(createRole.rejected, (state, { error }) => {
            state.createOneRoleStatus = 'failed';
            state.createOneRoleError = error?.message || 'An error occured';
        });
        builder
            .addCase(deleteRole.pending, (state) => {
            state.deleteOneRoleStatus = 'loading';
        })
            .addCase(deleteRole.fulfilled, (state, { payload }) => {
            state.deleteOneRoleStatus = 'succeeded';
            state.roles = state.roles.filter((r) => r._id !== payload._id);
        })
            .addCase(deleteRole.rejected, (state, { error }) => {
            state.deleteOneRoleStatus = 'failed';
            state.deleteOneRoleError = error?.message || 'An error occured';
        });
    },
});
export default slice.reducer;
